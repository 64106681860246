<template>
  <div>
    <other-services-component />
    <footer-component />
  </div>
</template>

<script>
import OtherServicesComponent from "@/components/OtherServicesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "OtherServices",
  title: "Other Services | Baja California Health Tourism",
  components: {
    OtherServicesComponent,
    FooterComponent,
  },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
